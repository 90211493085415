import React from 'react';
import CommitteeContacts from '../../components/committee/CommitteeContacts';
import DsekHeader from '../../components/committee/DsekHeader';
import '../../css/committee/Dgroup.css';

function Dgroup() {
    return (
        <>
            <DsekHeader />
            <div className="Dgroup">
                
            </div >
        </>
    );
}

export default Dgroup;
