import React from 'react';
import CommitteeContacts from '../../components/committee/CommitteeContacts';
import DsekHeader from '../../components/committee/DsekHeader';
import '../../css/committee/Staben.css';

function Staben() {
    return (
        <>
            <DsekHeader />
            <div className="Staben">
                
            </div >
        </>
    );
}

export default Staben;
